import React from 'react'
import Signin from '../componants/Signin'


const Admin = () => {
  return (
    <div>
      <Signin />
    </div>
  )
}

export default Admin